@import 'react-md/src/scss/react-md';

// Any variable overrides. The following just changes the default theme to use teal and purple.
$md-primary-color: $md-grey-500;
$md-secondary-color: $md-grey-50;

$md-list-desktop-font-size: 14px;
$md-list-primary-font-size: 16px;
$md-text-field-active-font-size: 16px;
$md-text-field-message-font-size: 16px;
$md-text-field-desktop-font-size: 16px;
$md-list-height: 56px;
$md-list-avatar-height: 72px;
$md-navigation-drawer-enforce-height: true;
