.md-display-4 {
  letter-spacing: -0.1px;
}

.md-display-3 {
  letter-spacing: -0.05px;
}

.md-headline, h2, .md-display-1, h1, .md-display-2 {
  letter-spacing: 0;
}

.md-title, h3 {
  letter-spacing: 0.5px;
}

.md-body-2, h6, .md-body-1, p, .md-subheading-1, h5, .md-subheading-2, h4 {
  letter-spacing: 0.1px;
}

.md-caption, caption {
  letter-spacing: 0.2px;
}

.md-display-1, h1,
.md-display-2,
.md-display-3,
.md-display-4,
.md-headline,
h2,
.md-title,
h3,
.md-subheading-1,
h5,
.md-subheading-2,
h4,
.md-body-1,
p,
.md-body-2,
h6,
.md-caption,
caption {
  margin: 0;
}

.md-display-1, h1,
.md-display-2,
.md-display-3,
.md-display-4,
.md-headline,
h2,
.md-title,
h3 {
  margin-bottom: 14px;
}

.md-subheading-1, h5,
.md-subheading-2,
h4,
.md-body-1,
p,
.md-body-2,
h6,
.md-caption,
caption {
  margin-bottom: 10px;
}

.md-display-3,
.md-display-4,
.md-title,
h3 {
  white-space: nowrap;
}

.md-display-1, h1,
.md-display-2,
.md-display-3,
.md-headline,
h2,
.md-subheading-1,
h5,
.md-subheading-2,
h4,
.md-body-1,
p,
.md-caption,
caption {
  font-weight: 400;
}

.md-title, h3,
.md-body-2,
h6 {
  font-weight: 500;
}

.md-display-4 {
  font-size: 112px;
  font-weight: 300;
  line-height: 128px;
}

.md-display-3 {
  font-size: 56px;
  line-height: 84px;
}

.md-display-2 {
  font-size: 45px;
  line-height: 48px;
}

.md-display-1, h1 {
  font-size: 34px;
  line-height: 40px;
}

.md-headline, h2 {
  font-size: 24px;
  line-height: 32px;
}

.md-title, h3 {
  font-size: 20px;
  line-height: 28px;
}

.md-subheading-2, h4 {
  line-height: 28px;
}

.md-subheading-1, h5 {
  line-height: 24px;
}

.md-body-1, p {
  line-height: 20px;
}

.md-body-2, h6 {
  line-height: 24px;
}

.md-caption, caption {
  font-size: 12px;
}

.md-picker-control, .md-text-left {
  text-align: left;
}

.md-calendar-date, .md-text-center {
  text-align: center;
}

.md-text-right {
  text-align: right;
}

.md-text-justify {
  text-align: justify;
}

.md-text-capitalize {
  text-transform: capitalize;
}

.md-text-lowercalse {
  text-transform: lowercase;
}

.md-text-uppercase {
  text-transform: uppercase;
}

.md-text-nowrap {
  white-space: nowrap;
}

.md-text-no-select {
  user-select: none;
}

.md-font-light {
  font-weight: 300;
}

.md-font-regular {
  font-weight: 400;
}

.md-clock-time-value, .md-btn .md-icon-text, .md-font-medium {
  font-weight: 500;
}

.md-font-semibold {
  font-weight: 600;
}

.md-font-bold {
  font-weight: 700;
}

.md-transition--sharp {
  transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
}

.md-transition--standard {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.md-drop-down-leave.md-drop-down-leave-active, .md-transition--acceleration {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.md-drop-down-enter.md-drop-down-enter-active,
.md-transition--decceleration,
.md-transition--deceleration {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.md-inline-block, .md-calendar-date {
  display: inline-block;
  vertical-align: bottom;
}

.md-full-width {
  width: 100%;
}

.md-block-centered, .md-bottom-nav .md-icon, .md-text-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.md-divider--expand-from-right::after, .md-collapser--card, .md-cell--right, .md-grid.md-grid--no-spacing > .md-cell.md-cell--right {
  margin-left: auto;
}

.md-divider--expand-from-right::after, .md-collapser--card, .md-cell--right, .md-grid.md-grid--no-spacing > .md-cell.md-cell--right {
  margin-left: auto;
}

.md-tabs, .md-list, .md-list-unstyled, .md-expansion-panel-list {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.md-media object, .md-media embed, .md-media video, .md-media svg, .md-media iframe, .md-media img {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}

@media screen and (min-width: 320px) {
  .md-subheading-2,
  .md-subheading-1 {
    font-size: 16px;
  }
  .md-body-2,
  .md-body-1 {
    font-size: 14px;
  }
  h5,
  h4 {
    font-size: 16px;
  }
  h6,
  p {
    font-size: 14px;
  }
}
@media screen and (min-width: 1025px) {
  .md-subheading-2,
  .md-subheading-1 {
    font-size: 15px;
  }
  .md-body-2,
  .md-body-1 {
    font-size: 13px;
  }
  h5,
  h4 {
    font-size: 15px;
  }
  h6,
  p {
    font-size: 13px;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

html {
  background: #fafafa;
  font-size: 14px;
  min-width: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.4285714286;
  text-rendering: optimizeLegibility;
}

.md-headline, h2,
.md-title,
h3,
.md-subheading-2,
h4,
.md-subheading-1,
h5,
.md-body-2,
h6,
.md-body-1,
p {
  color: rgba(0, 0, 0, 0.87);
}

.md-display-4,
.md-display-3,
.md-display-2,
.md-display-1,
h1,
.md-caption,
caption {
  color: rgba(0, 0, 0, 0.54);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
input,
textarea,
html {
  font-family: "Roboto", sans-serif;
}

.md-text-container {
  max-width: 640px;
  width: 100%;
}
.md-text-container.md-text-container.md-cell {
  margin-left: auto;
  margin-right: auto;
}

.md-fake-btn {
  background: transparent;
  position: relative;
}
.md-fake-btn--no-outline {
  outline-style: none;
}

.md-no-scroll.md-no-scroll {
  overflow: hidden;
  position: fixed;
}

.md-pointer--hover:hover {
  cursor: pointer;
}

.md-pointer--none {
  pointer-events: none;
}

.md-content-jump {
  left: -1000px;
  position: absolute;
  top: -1000px;
}
.md-content-jump:active, .md-content-jump:focus {
  left: 0;
  top: 0;
}

.md-grid {
  align-items: stretch;
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
}
.md-grid.md-grid--no-spacing {
  padding: 0;
}
.md-grid.md-grid--no-spacing > .md-cell {
  margin: 0;
}
.md-grid--stacked {
  flex-direction: column;
}

.md-cell--top {
  align-self: flex-start;
}
.md-cell--middle {
  align-self: center;
}
.md-cell--center {
  margin-left: auto;
  margin-right: auto;
}
.md-cell--bottom {
  align-self: flex-end;
}
.md-cell--stretch {
  align-self: stretch;
}
@media (max-width: 599px) {
  .md-grid {
    padding: 8px;
  }
  .md-cell {
    width: calc(100% - 16px);
    margin: 8px;
  }
  .md-grid.md-grid--no-spacing > .md-cell {
    width: 100%;
  }
  .md-cell--phone-hidden {
    display: none !important;
  }
  .md-cell--order-1,
  .md-cell--order-1-phone.md-cell--order-1-phone {
    order: 1;
  }
  .md-cell--order-2,
  .md-cell--order-2-phone.md-cell--order-2-phone {
    order: 2;
  }
  .md-cell--order-3,
  .md-cell--order-3-phone.md-cell--order-3-phone {
    order: 3;
  }
  .md-cell--order-4,
  .md-cell--order-4-phone.md-cell--order-4-phone {
    order: 4;
  }
  .md-cell--order-5,
  .md-cell--order-5-phone.md-cell--order-5-phone {
    order: 5;
  }
  .md-cell--order-6,
  .md-cell--order-6-phone.md-cell--order-6-phone {
    order: 6;
  }
  .md-cell--order-7,
  .md-cell--order-7-phone.md-cell--order-7-phone {
    order: 7;
  }
  .md-cell--order-8,
  .md-cell--order-8-phone.md-cell--order-8-phone {
    order: 8;
  }
  .md-cell--order-9,
  .md-cell--order-9-phone.md-cell--order-9-phone {
    order: 9;
  }
  .md-cell--order-10,
  .md-cell--order-10-phone.md-cell--order-10-phone {
    order: 10;
  }
  .md-cell--order-11,
  .md-cell--order-11-phone.md-cell--order-11-phone {
    order: 11;
  }
  .md-cell--order-12,
  .md-cell--order-12-phone.md-cell--order-12-phone {
    order: 12;
  }
  .md-cell--1,
  .md-cell--1-phone.md-cell--1-phone {
    width: calc(25% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--1,
  .md-grid.md-grid--no-spacing > .md-cell--1-phone.md-cell--1-phone {
    width: 25%;
  }
  .md-cell--2,
  .md-cell--2-phone.md-cell--2-phone {
    width: calc(50% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--2,
  .md-grid.md-grid--no-spacing > .md-cell--2-phone.md-cell--2-phone {
    width: 50%;
  }
  .md-cell--3,
  .md-cell--3-phone.md-cell--3-phone {
    width: calc(75% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--3,
  .md-grid.md-grid--no-spacing > .md-cell--3-phone.md-cell--3-phone {
    width: 75%;
  }
  .md-cell--4,
  .md-cell--4-phone.md-cell--4-phone {
    width: calc(100% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--4,
  .md-grid.md-grid--no-spacing > .md-cell--4-phone.md-cell--4-phone {
    width: 100%;
  }
  .md-cell--5,
  .md-cell--5-phone.md-cell--5-phone {
    width: calc(100% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--5,
  .md-grid.md-grid--no-spacing > .md-cell--5-phone.md-cell--5-phone {
    width: 100%;
  }
  .md-cell--6,
  .md-cell--6-phone.md-cell--6-phone {
    width: calc(100% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--6,
  .md-grid.md-grid--no-spacing > .md-cell--6-phone.md-cell--6-phone {
    width: 100%;
  }
  .md-cell--7,
  .md-cell--7-phone.md-cell--7-phone {
    width: calc(100% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--7,
  .md-grid.md-grid--no-spacing > .md-cell--7-phone.md-cell--7-phone {
    width: 100%;
  }
  .md-cell--8,
  .md-cell--8-phone.md-cell--8-phone {
    width: calc(100% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--8,
  .md-grid.md-grid--no-spacing > .md-cell--8-phone.md-cell--8-phone {
    width: 100%;
  }
  .md-cell--9,
  .md-cell--9-phone.md-cell--9-phone {
    width: calc(100% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--9,
  .md-grid.md-grid--no-spacing > .md-cell--9-phone.md-cell--9-phone {
    width: 100%;
  }
  .md-cell--10,
  .md-cell--10-phone.md-cell--10-phone {
    width: calc(100% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--10,
  .md-grid.md-grid--no-spacing > .md-cell--10-phone.md-cell--10-phone {
    width: 100%;
  }
  .md-cell--11,
  .md-cell--11-phone.md-cell--11-phone {
    width: calc(100% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--11,
  .md-grid.md-grid--no-spacing > .md-cell--11-phone.md-cell--11-phone {
    width: 100%;
  }
  .md-cell--12,
  .md-cell--12-phone.md-cell--12-phone {
    width: calc(100% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--12,
  .md-grid.md-grid--no-spacing > .md-cell--12-phone.md-cell--12-phone {
    width: 100%;
  }
  .md-cell--1-offset,
  .md-cell--1-phone-offset.md-cell--1-phone-offset {
    margin-left: calc(25% + 8px);
  }
  .md-grid--no-spacing > .md-cell--1-offset,
  .md-grid--no-spacing > .md-cell--1-phone-offset.md-cell--1-phone-offset {
    margin-left: 25%;
  }
  .md-cell--2-offset,
  .md-cell--2-phone-offset.md-cell--2-phone-offset {
    margin-left: calc(50% + 8px);
  }
  .md-grid--no-spacing > .md-cell--2-offset,
  .md-grid--no-spacing > .md-cell--2-phone-offset.md-cell--2-phone-offset {
    margin-left: 50%;
  }
  .md-cell--3-offset,
  .md-cell--3-phone-offset.md-cell--3-phone-offset {
    margin-left: calc(75% + 8px);
  }
  .md-grid--no-spacing > .md-cell--3-offset,
  .md-grid--no-spacing > .md-cell--3-phone-offset.md-cell--3-phone-offset {
    margin-left: 75%;
  }
}
@media (min-width: 600px) and (max-width: 839px) {
  .md-grid {
    padding: 8px;
  }
  .md-cell {
    width: calc(50% - 16px);
    margin: 8px;
  }
  .md-grid.md-grid--no-spacing > .md-cell {
    width: 50%;
  }
  .md-cell--tablet-hidden {
    display: none !important;
  }
  .md-cell--order-1,
  .md-cell--order-1-tablet.md-cell--order-1-tablet {
    order: 1;
  }
  .md-cell--order-2,
  .md-cell--order-2-tablet.md-cell--order-2-tablet {
    order: 2;
  }
  .md-cell--order-3,
  .md-cell--order-3-tablet.md-cell--order-3-tablet {
    order: 3;
  }
  .md-cell--order-4,
  .md-cell--order-4-tablet.md-cell--order-4-tablet {
    order: 4;
  }
  .md-cell--order-5,
  .md-cell--order-5-tablet.md-cell--order-5-tablet {
    order: 5;
  }
  .md-cell--order-6,
  .md-cell--order-6-tablet.md-cell--order-6-tablet {
    order: 6;
  }
  .md-cell--order-7,
  .md-cell--order-7-tablet.md-cell--order-7-tablet {
    order: 7;
  }
  .md-cell--order-8,
  .md-cell--order-8-tablet.md-cell--order-8-tablet {
    order: 8;
  }
  .md-cell--order-9,
  .md-cell--order-9-tablet.md-cell--order-9-tablet {
    order: 9;
  }
  .md-cell--order-10,
  .md-cell--order-10-tablet.md-cell--order-10-tablet {
    order: 10;
  }
  .md-cell--order-11,
  .md-cell--order-11-tablet.md-cell--order-11-tablet {
    order: 11;
  }
  .md-cell--order-12,
  .md-cell--order-12-tablet.md-cell--order-12-tablet {
    order: 12;
  }
  .md-cell--1,
  .md-cell--1-tablet.md-cell--1-tablet {
    width: calc(12.5% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--1,
  .md-grid.md-grid--no-spacing > .md-cell--1-tablet.md-cell--1-tablet {
    width: 12.5%;
  }
  .md-cell--2,
  .md-cell--2-tablet.md-cell--2-tablet {
    width: calc(25% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--2,
  .md-grid.md-grid--no-spacing > .md-cell--2-tablet.md-cell--2-tablet {
    width: 25%;
  }
  .md-cell--3,
  .md-cell--3-tablet.md-cell--3-tablet {
    width: calc(37.5% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--3,
  .md-grid.md-grid--no-spacing > .md-cell--3-tablet.md-cell--3-tablet {
    width: 37.5%;
  }
  .md-cell--4,
  .md-cell--4-tablet.md-cell--4-tablet {
    width: calc(50% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--4,
  .md-grid.md-grid--no-spacing > .md-cell--4-tablet.md-cell--4-tablet {
    width: 50%;
  }
  .md-cell--5,
  .md-cell--5-tablet.md-cell--5-tablet {
    width: calc(62.5% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--5,
  .md-grid.md-grid--no-spacing > .md-cell--5-tablet.md-cell--5-tablet {
    width: 62.5%;
  }
  .md-cell--6,
  .md-cell--6-tablet.md-cell--6-tablet {
    width: calc(75% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--6,
  .md-grid.md-grid--no-spacing > .md-cell--6-tablet.md-cell--6-tablet {
    width: 75%;
  }
  .md-cell--7,
  .md-cell--7-tablet.md-cell--7-tablet {
    width: calc(87.5% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--7,
  .md-grid.md-grid--no-spacing > .md-cell--7-tablet.md-cell--7-tablet {
    width: 87.5%;
  }
  .md-cell--8,
  .md-cell--8-tablet.md-cell--8-tablet {
    width: calc(100% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--8,
  .md-grid.md-grid--no-spacing > .md-cell--8-tablet.md-cell--8-tablet {
    width: 100%;
  }
  .md-cell--9,
  .md-cell--9-tablet.md-cell--9-tablet {
    width: calc(100% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--9,
  .md-grid.md-grid--no-spacing > .md-cell--9-tablet.md-cell--9-tablet {
    width: 100%;
  }
  .md-cell--10,
  .md-cell--10-tablet.md-cell--10-tablet {
    width: calc(100% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--10,
  .md-grid.md-grid--no-spacing > .md-cell--10-tablet.md-cell--10-tablet {
    width: 100%;
  }
  .md-cell--11,
  .md-cell--11-tablet.md-cell--11-tablet {
    width: calc(100% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--11,
  .md-grid.md-grid--no-spacing > .md-cell--11-tablet.md-cell--11-tablet {
    width: 100%;
  }
  .md-cell--12,
  .md-cell--12-tablet.md-cell--12-tablet {
    width: calc(100% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--12,
  .md-grid.md-grid--no-spacing > .md-cell--12-tablet.md-cell--12-tablet {
    width: 100%;
  }
  .md-cell--1-offset,
  .md-cell--1-tablet-offset.md-cell--1-tablet-offset {
    margin-left: calc(12.5% + 8px);
  }
  .md-grid--no-spacing > .md-cell--1-offset,
  .md-grid--no-spacing > .md-cell--1-tablet-offset.md-cell--1-tablet-offset {
    margin-left: 12.5%;
  }
  .md-cell--2-offset,
  .md-cell--2-tablet-offset.md-cell--2-tablet-offset {
    margin-left: calc(25% + 8px);
  }
  .md-grid--no-spacing > .md-cell--2-offset,
  .md-grid--no-spacing > .md-cell--2-tablet-offset.md-cell--2-tablet-offset {
    margin-left: 25%;
  }
  .md-cell--3-offset,
  .md-cell--3-tablet-offset.md-cell--3-tablet-offset {
    margin-left: calc(37.5% + 8px);
  }
  .md-grid--no-spacing > .md-cell--3-offset,
  .md-grid--no-spacing > .md-cell--3-tablet-offset.md-cell--3-tablet-offset {
    margin-left: 37.5%;
  }
  .md-cell--4-offset,
  .md-cell--4-tablet-offset.md-cell--4-tablet-offset {
    margin-left: calc(50% + 8px);
  }
  .md-grid--no-spacing > .md-cell--4-offset,
  .md-grid--no-spacing > .md-cell--4-tablet-offset.md-cell--4-tablet-offset {
    margin-left: 50%;
  }
  .md-cell--5-offset,
  .md-cell--5-tablet-offset.md-cell--5-tablet-offset {
    margin-left: calc(62.5% + 8px);
  }
  .md-grid--no-spacing > .md-cell--5-offset,
  .md-grid--no-spacing > .md-cell--5-tablet-offset.md-cell--5-tablet-offset {
    margin-left: 62.5%;
  }
  .md-cell--6-offset,
  .md-cell--6-tablet-offset.md-cell--6-tablet-offset {
    margin-left: calc(75% + 8px);
  }
  .md-grid--no-spacing > .md-cell--6-offset,
  .md-grid--no-spacing > .md-cell--6-tablet-offset.md-cell--6-tablet-offset {
    margin-left: 75%;
  }
  .md-cell--7-offset,
  .md-cell--7-tablet-offset.md-cell--7-tablet-offset {
    margin-left: calc(87.5% + 8px);
  }
  .md-grid--no-spacing > .md-cell--7-offset,
  .md-grid--no-spacing > .md-cell--7-tablet-offset.md-cell--7-tablet-offset {
    margin-left: 87.5%;
  }
}
@media (min-width: 840px) {
  .md-grid {
    padding: 8px;
  }
  .md-cell {
    width: calc(33.3333333333% - 16px);
    margin: 8px;
  }
  .md-grid.md-grid--no-spacing > .md-cell {
    width: 33.3333333333%;
  }
  .md-cell--desktop-hidden {
    display: none !important;
  }
  .md-cell--order-1,
  .md-cell--order-1-desktop.md-cell--order-1-desktop {
    order: 1;
  }
  .md-cell--order-2,
  .md-cell--order-2-desktop.md-cell--order-2-desktop {
    order: 2;
  }
  .md-cell--order-3,
  .md-cell--order-3-desktop.md-cell--order-3-desktop {
    order: 3;
  }
  .md-cell--order-4,
  .md-cell--order-4-desktop.md-cell--order-4-desktop {
    order: 4;
  }
  .md-cell--order-5,
  .md-cell--order-5-desktop.md-cell--order-5-desktop {
    order: 5;
  }
  .md-cell--order-6,
  .md-cell--order-6-desktop.md-cell--order-6-desktop {
    order: 6;
  }
  .md-cell--order-7,
  .md-cell--order-7-desktop.md-cell--order-7-desktop {
    order: 7;
  }
  .md-cell--order-8,
  .md-cell--order-8-desktop.md-cell--order-8-desktop {
    order: 8;
  }
  .md-cell--order-9,
  .md-cell--order-9-desktop.md-cell--order-9-desktop {
    order: 9;
  }
  .md-cell--order-10,
  .md-cell--order-10-desktop.md-cell--order-10-desktop {
    order: 10;
  }
  .md-cell--order-11,
  .md-cell--order-11-desktop.md-cell--order-11-desktop {
    order: 11;
  }
  .md-cell--order-12,
  .md-cell--order-12-desktop.md-cell--order-12-desktop {
    order: 12;
  }
  .md-cell--1,
  .md-cell--1-desktop.md-cell--1-desktop {
    width: calc(8.3333333333% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--1,
  .md-grid.md-grid--no-spacing > .md-cell--1-desktop.md-cell--1-desktop {
    width: 8.3333333333%;
  }
  .md-cell--2,
  .md-cell--2-desktop.md-cell--2-desktop {
    width: calc(16.6666666667% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--2,
  .md-grid.md-grid--no-spacing > .md-cell--2-desktop.md-cell--2-desktop {
    width: 16.6666666667%;
  }
  .md-cell--3,
  .md-cell--3-desktop.md-cell--3-desktop {
    width: calc(25% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--3,
  .md-grid.md-grid--no-spacing > .md-cell--3-desktop.md-cell--3-desktop {
    width: 25%;
  }
  .md-cell--4,
  .md-cell--4-desktop.md-cell--4-desktop {
    width: calc(33.3333333333% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--4,
  .md-grid.md-grid--no-spacing > .md-cell--4-desktop.md-cell--4-desktop {
    width: 33.3333333333%;
  }
  .md-cell--5,
  .md-cell--5-desktop.md-cell--5-desktop {
    width: calc(41.6666666667% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--5,
  .md-grid.md-grid--no-spacing > .md-cell--5-desktop.md-cell--5-desktop {
    width: 41.6666666667%;
  }
  .md-cell--6,
  .md-cell--6-desktop.md-cell--6-desktop {
    width: calc(50% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--6,
  .md-grid.md-grid--no-spacing > .md-cell--6-desktop.md-cell--6-desktop {
    width: 50%;
  }
  .md-cell--7,
  .md-cell--7-desktop.md-cell--7-desktop {
    width: calc(58.3333333333% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--7,
  .md-grid.md-grid--no-spacing > .md-cell--7-desktop.md-cell--7-desktop {
    width: 58.3333333333%;
  }
  .md-cell--8,
  .md-cell--8-desktop.md-cell--8-desktop {
    width: calc(66.6666666667% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--8,
  .md-grid.md-grid--no-spacing > .md-cell--8-desktop.md-cell--8-desktop {
    width: 66.6666666667%;
  }
  .md-cell--9,
  .md-cell--9-desktop.md-cell--9-desktop {
    width: calc(75% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--9,
  .md-grid.md-grid--no-spacing > .md-cell--9-desktop.md-cell--9-desktop {
    width: 75%;
  }
  .md-cell--10,
  .md-cell--10-desktop.md-cell--10-desktop {
    width: calc(83.3333333333% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--10,
  .md-grid.md-grid--no-spacing > .md-cell--10-desktop.md-cell--10-desktop {
    width: 83.3333333333%;
  }
  .md-cell--11,
  .md-cell--11-desktop.md-cell--11-desktop {
    width: calc(91.6666666667% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--11,
  .md-grid.md-grid--no-spacing > .md-cell--11-desktop.md-cell--11-desktop {
    width: 91.6666666667%;
  }
  .md-cell--12,
  .md-cell--12-desktop.md-cell--12-desktop {
    width: calc(100% - 16px);
  }
  .md-grid.md-grid--no-spacing > .md-cell--12,
  .md-grid.md-grid--no-spacing > .md-cell--12-desktop.md-cell--12-desktop {
    width: 100%;
  }
  .md-cell--1-offset,
  .md-cell--1-desktop-offset.md-cell--1-desktop-offset {
    margin-left: calc(8.3333333333% + 8px);
  }
  .md-grid--no-spacing > .md-cell--1-offset,
  .md-grid--no-spacing > .md-cell--1-desktop-offset.md-cell--1-desktop-offset {
    margin-left: 8.3333333333%;
  }
  .md-cell--2-offset,
  .md-cell--2-desktop-offset.md-cell--2-desktop-offset {
    margin-left: calc(16.6666666667% + 8px);
  }
  .md-grid--no-spacing > .md-cell--2-offset,
  .md-grid--no-spacing > .md-cell--2-desktop-offset.md-cell--2-desktop-offset {
    margin-left: 16.6666666667%;
  }
  .md-cell--3-offset,
  .md-cell--3-desktop-offset.md-cell--3-desktop-offset {
    margin-left: calc(25% + 8px);
  }
  .md-grid--no-spacing > .md-cell--3-offset,
  .md-grid--no-spacing > .md-cell--3-desktop-offset.md-cell--3-desktop-offset {
    margin-left: 25%;
  }
  .md-cell--4-offset,
  .md-cell--4-desktop-offset.md-cell--4-desktop-offset {
    margin-left: calc(33.3333333333% + 8px);
  }
  .md-grid--no-spacing > .md-cell--4-offset,
  .md-grid--no-spacing > .md-cell--4-desktop-offset.md-cell--4-desktop-offset {
    margin-left: 33.3333333333%;
  }
  .md-cell--5-offset,
  .md-cell--5-desktop-offset.md-cell--5-desktop-offset {
    margin-left: calc(41.6666666667% + 8px);
  }
  .md-grid--no-spacing > .md-cell--5-offset,
  .md-grid--no-spacing > .md-cell--5-desktop-offset.md-cell--5-desktop-offset {
    margin-left: 41.6666666667%;
  }
  .md-cell--6-offset,
  .md-cell--6-desktop-offset.md-cell--6-desktop-offset {
    margin-left: calc(50% + 8px);
  }
  .md-grid--no-spacing > .md-cell--6-offset,
  .md-grid--no-spacing > .md-cell--6-desktop-offset.md-cell--6-desktop-offset {
    margin-left: 50%;
  }
  .md-cell--7-offset,
  .md-cell--7-desktop-offset.md-cell--7-desktop-offset {
    margin-left: calc(58.3333333333% + 8px);
  }
  .md-grid--no-spacing > .md-cell--7-offset,
  .md-grid--no-spacing > .md-cell--7-desktop-offset.md-cell--7-desktop-offset {
    margin-left: 58.3333333333%;
  }
  .md-cell--8-offset,
  .md-cell--8-desktop-offset.md-cell--8-desktop-offset {
    margin-left: calc(66.6666666667% + 8px);
  }
  .md-grid--no-spacing > .md-cell--8-offset,
  .md-grid--no-spacing > .md-cell--8-desktop-offset.md-cell--8-desktop-offset {
    margin-left: 66.6666666667%;
  }
  .md-cell--9-offset,
  .md-cell--9-desktop-offset.md-cell--9-desktop-offset {
    margin-left: calc(75% + 8px);
  }
  .md-grid--no-spacing > .md-cell--9-offset,
  .md-grid--no-spacing > .md-cell--9-desktop-offset.md-cell--9-desktop-offset {
    margin-left: 75%;
  }
  .md-cell--10-offset,
  .md-cell--10-desktop-offset.md-cell--10-desktop-offset {
    margin-left: calc(83.3333333333% + 8px);
  }
  .md-grid--no-spacing > .md-cell--10-offset,
  .md-grid--no-spacing > .md-cell--10-desktop-offset.md-cell--10-desktop-offset {
    margin-left: 83.3333333333%;
  }
  .md-cell--11-offset,
  .md-cell--11-desktop-offset.md-cell--11-desktop-offset {
    margin-left: calc(91.6666666667% + 8px);
  }
  .md-grid--no-spacing > .md-cell--11-offset,
  .md-grid--no-spacing > .md-cell--11-desktop-offset.md-cell--11-desktop-offset {
    margin-left: 91.6666666667%;
  }
}
.md-autocomplete-container {
  position: relative;
}

.md-autocomplete-suggestion {
  color: rgba(0, 0, 0, 0.54);
  line-height: 1.15;
  overflow: hidden;
  position: absolute;
  top: 12px;
  white-space: nowrap;
}

@media screen and (min-width: 320px) {
  .md-autocomplete-suggestion {
    font-size: 16px;
  }
  .md-autocomplete-suggestion--floating {
    top: 37px;
  }
  .md-autocomplete-suggestion--block {
    top: 18px;
  }
}
@media screen and (min-width: 1025px) {
  .md-autocomplete-suggestion {
    font-size: 16px;
  }
  .md-autocomplete-suggestion--floating {
    top: 33px;
  }
  .md-autocomplete-suggestion--block {
    top: 15px;
  }
}
.md-avatar {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  height: 40px;
  overflow: hidden;
  text-align: center;
  width: 40px;
}
.md-avatar .md-icon {
  color: inherit;
}
.md-avatar--icon-sized {
  height: 24px;
  width: 24px;
}

.md-avatar-img {
  height: 100%;
  width: auto;
}

.md-avatar-content {
  align-items: center;
  display: flex;
  font-size: 24px;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.md-avatar--default {
  background: #616161;
  color: #f5f5f5;
}

@media screen and (min-width: 1025px) {
  .md-avatar-content {
    font-size: 20px;
  }
  .md-avatar--icon-sized {
    height: 20px;
    width: 20px;
  }
}
.md-avatar--red {
  background: #d50000;
  color: #ffebee;
}

.md-avatar--pink {
  background: #d81b60;
  color: #fff;
}

.md-avatar--purple {
  background: #7b1fa2;
  color: #e1bee7;
}

.md-avatar--deep-purple {
  background: #311b92;
  color: #d1c4e9;
}

.md-avatar--indigo {
  background: #3949ab;
  color: #c5cae9;
}

.md-avatar--blue {
  background: #2962ff;
  color: #fff;
}

.md-avatar--light-blue {
  background: #4fc3f7;
  color: #311b92;
}

.md-avatar--cyan {
  background: #26c6da;
  color: #004d40;
}

.md-avatar--teal {
  background: #1de9b6;
  color: #004d40;
}

.md-avatar--green {
  background: #2e7d32;
  color: #e8f5e9;
}

.md-avatar--light-green {
  background: #aed581;
  color: #1b5e20;
}

.md-avatar--lime {
  background: #d4e157;
  color: #00695c;
}

.md-avatar--yellow {
  background: #ff0;
  color: #795548;
}

.md-avatar--amber {
  background: #ffca28;
  color: #4e342e;
}

.md-avatar--orange {
  background: #fb8c00;
  color: #212121;
}

.md-avatar--deep-orange {
  background: #ff3d00;
  color: #212121;
}

.md-avatar--brown {
  background: #795548;
  color: #efebe9;
}

.md-avatar--grey {
  background: #616161;
  color: #f5f5f5;
}

.md-avatar--blue-grey {
  background: #455a64;
  color: #eceff1;
}

.md-badge-container {
  position: relative;
}

.md-badge {
  position: absolute;
  right: -8px;
  top: -8px;
}
.md-badge--circular {
  align-items: center;
  border-radius: 50%;
  display: flex;
  font-size: 10px;
  height: 24px;
  justify-content: center;
  width: 24px;
}
.md-badge--default {
  background: rgba(0, 0, 0, 0.2);
}

.md-bottom-navigation {
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  width: 100%;
  z-index: 11;
}
.md-bottom-navigation--shifting {
  transition-duration: 0.3s;
  transition-property: background;
}
.md-bottom-navigation--dynamic {
  transform: translate3d(0, 0, 0);
  transition-duration: 0.3s;
  transition-property: background, transform;
}
.md-bottom-navigation--dynamic-inactive {
  transform: translate3d(0, 100%, 0);
}

.md-bottom-navigation-offset {
  padding-bottom: 56px;
}

.md-bottom-nav {
  color: inherit;
  display: block;
  flex-grow: 1;
  font-size: 12px;
  height: 56px;
  max-width: 168px;
  padding: 8px 12px 10px;
  text-align: center;
  text-decoration: none;
  user-select: none;
}
.md-bottom-nav--active {
  flex-shrink: 0;
  font-size: 14px;
  padding-top: 6px;
}
.md-bottom-nav--fixed {
  min-width: 80px;
}
.md-bottom-nav--shifting {
  min-width: 96px;
  position: static;
  transition-duration: 0.15s;
  transition-property: max-width;
}
.md-bottom-nav--shifting-inactive {
  max-width: 96px;
  min-width: 56px;
  padding-top: 16px;
}
.md-bottom-nav--shifting .md-ink-container {
  overflow: visible;
}
.md-bottom-nav--shifting .md-ink {
  background: rgba(255, 255, 255, 0.12);
}
.md-bottom-nav-label {
  transition-duration: 0.15s;
  transition-property: color, font-size;
}
.md-bottom-nav-label--shifting-inactive {
  max-width: 32px;
  overflow: hidden;
  white-space: nowrap;
}
a.md-btn {
  text-decoration: none;
}

.md-btn {
  background: transparent;
  border: 0;
  position: relative;
  transition-duration: 0.15s;
  transition-property: background, color;
}
.md-btn[disabled] * {
  pointer-events: none;
}
.md-btn--tooltip {
  overflow: visible;
}
.md-btn:focus {
  outline-style: none;
}
.md-btn .md-icon-separator {
  height: 100%;
}
.md-btn--hover {
  background: rgba(153, 153, 153, 0.12);
}

.md-btn--color-primary-active {
  background: rgba(158, 158, 158, 0.12);
}

.md-btn--color-secondary-active {
  background: rgba(250, 250, 250, 0.12);
}

.md-btn--text {
  border-radius: 2px;
  font-weight: 500;
  min-width: 88px;
  padding: 8px 16px;
  text-transform: uppercase;
}

.md-btn--raised {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transition: background 0.15s, box-shadow 0.3s, color 0.15s;
}

.md-btn--raised-disabled {
  background: rgba(0, 0, 0, 0.12);
}

.md-btn--raised-pressed {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4);
}

.md-btn--icon {
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.54);
  height: 48px;
  padding: 12px;
  width: 48px;
}

.md-btn--floating {
  height: 56px;
  padding: 16px;
  transition-property: background, box-shadow, color;
  width: 56px;
}

.md-btn--floating-mini {
  height: 40px;
  padding: 8px;
  width: 40px;
}

.md-btn--fixed {
  position: fixed;
  z-index: 10;
}

@media screen and (min-width: 320px) {
  .md-btn--text {
    height: 36px;
    margin-bottom: 6px;
    margin-top: 6px;
    font-size: 14px;
  }
  .md-btn--text::before, .md-btn--text::after {
    content: "";
    height: 6px;
    left: 0;
    position: absolute;
    right: 0;
  }
  .md-btn--text::before {
    top: -6px;
  }
  .md-btn--text::after {
    bottom: -6px;
  }
  .md-btn--fixed-tl {
    left: 16px;
    top: 16px;
  }
  .md-btn--fixed-tr {
    right: 16px;
    top: 16px;
  }
  .md-btn--fixed-bl {
    bottom: 16px;
    left: 16px;
  }
  .md-btn--fixed-br {
    bottom: 16px;
    right: 16px;
  }
}
@media screen and (min-width: 1025px) {
  .md-btn--text {
    height: 32px;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 13px;
  }
  .md-btn--text::before, .md-btn--text::after {
    display: none;
    visibility: hidden;
  }
  .md-btn--text::before {
    top: 0;
  }
  .md-btn--text::after {
    bottom: 0;
  }
  .md-btn--fixed-tl {
    left: 24px;
    top: 24px;
  }
  .md-btn--fixed-tr {
    right: 24px;
    top: 24px;
  }
  .md-btn--fixed-bl {
    bottom: 24px;
    left: 24px;
  }
  .md-btn--fixed-br {
    bottom: 24px;
    right: 24px;
  }
  .md-btn--icon {
    height: 40px;
    width: 40px;
    padding: 10px;
  }
  .md-btn--floating {
    height: 48px;
    padding: 14px;
    width: 48px;
  }
  .md-btn--floating-mini {
    height: 40px;
    padding: 10px;
    width: 40px;
  }
}
.md-card {
  display: block;
}
.md-card--raise {
  transition-duration: 0.3s;
  transition-property: box-shadow;
}

.md-collapser--card {
  transition-duration: 0.3s;
  transition-property: background, transform;
}

.md-card-text {
  font-size: 14px;
  padding: 16px;
}
.md-card-text p {
  font-size: inherit;
}
.md-card-text p:last-child {
  margin-bottom: 0;
}
.md-card-text:last-child {
  padding-bottom: 24px;
}

.md-card-title {
  align-items: center;
  display: flex;
  padding: 16px;
}
.md-card-title:last-child {
  padding-bottom: 24px;
}
.md-card-title--primary {
  padding-top: 24px;
}
.md-card-title--title {
  font-size: 14px;
  line-height: 1.4285714286;
  margin: 0;
  white-space: normal;
}
.md-card-title--large {
  font-size: 24px;
}
.md-card-title--one-line {
  overflow: hidden;
}
.md-card-title--one-line .md-card-title--title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.md-avatar--card {
  flex-shrink: 0;
  margin-right: 16px;
}

.md-dialog-footer--card {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.md-dialog-footer--card-centered {
  justify-content: center;
}

.md-card--table .md-card-title {
  padding-left: 24px;
}

.md-chip {
  align-items: center;
  background: #e0e0e0;
  border: 0;
  border-radius: 16px;
  display: inline-flex;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  transition-duration: 0.15s;
  transition-property: box-shadow, background;
  vertical-align: top;
  white-space: nowrap;
}
.md-chip:focus {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  outline-style: none;
}

.md-chip--hover.md-chip--hover {
  background: #616161;
}

.md-chip-text {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  transition-duration: 0.15s;
  transition-property: color;
}

.md-chip .md-avatar {
  border: 0;
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 32px;
}

.md-chip--avatar {
  padding-left: 40px;
}

.md-chip-icon {
  color: rgba(0, 0, 0, 0.54);
  margin-left: 4px;
  margin-right: 4px;
  position: absolute;
  right: 0;
  top: 4px;
  z-index: 1;
}

.md-chip-icon--rotate {
  transform: rotate3d(0, 0, 1, 45deg);
}

.md-chip--remove {
  padding-right: 32px;
}

.md-chip-text--contact {
  font-size: 14px;
}

.md-chip-text--hover {
  color: #fff;
}

@media screen and (min-width: 1025px) {
  .md-chip-icon {
    margin-left: 6px;
    margin-right: 6px;
    top: 6px;
  }
}
.md-collapser.md-collapser {
  transition-duration: 0.15s;
  transition-property: transform;
}
.md-collapser .md-tooltip-container {
  transform: rotate3d(0, 0, 1, 0deg);
  transition-duration: 0.15s;
  transition-property: transform;
}
.md-collapser--flipped {
  transform: rotate3d(0, 0, 1, 180deg);
}
.md-collapser--flipped .md-tooltip-container {
  transform: rotate3d(0, 0, 1, -180deg);
}

.md-data-table {
  border-collapse: collapse;
  max-width: 100%;
}
.md-data-table--full-width {
  width: 100%;
}
.md-data-table--responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

thead .md-table-row,
tbody .md-table-row:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

tbody .md-table-row {
  transition-duration: 0.15s;
  transition-property: background;
}
tbody .md-table-row--active {
  background: #f5f5f5;
}
@media (min-device-width: 1025px) {
  tbody .md-table-row--hover {
    background: #eee;
  }
}

.md-table-column {
  line-height: normal;
  padding-bottom: 0;
  padding-right: 24px;
  padding-top: 0;
  vertical-align: middle;
  white-space: nowrap;
}
.md-table-column:first-child {
  padding-left: 24px;
}
.md-table-column--relative {
  position: relative;
}
.md-table-column--adjusted {
  padding-right: 56px;
}
.md-table-column--header {
  font-size: 12px;
  font-weight: 500;
  height: 56px;
}
.md-table-column--header .md-icon {
  font-size: 16px;
}
.md-table-column--data {
  font-size: 13px;
  height: 48px;
}
.md-table-column--plain {
  height: 48px;
  white-space: normal;
}
.md-table-column--grow {
  width: 100%;
}
.md-table-column--select-header {
  padding-left: 16px;
}
.md-table-column .md-icon-separator {
  line-height: inherit;
}

.md-table-checkbox .md-selection-control-label {
  display: flex;
}
.md-table-checkbox .md-selection-control-container {
  margin-left: 12px;
  margin-right: 12px;
}

.md-edit-dialog.md-dialog {
  width: 250px;
}
.md-edit-dialog__label {
  overflow: hidden;
  padding: 16px 0;
  text-overflow: ellipsis;
  width: 250px;
}
@media screen and (max-width: 1024px) {
  .md-edit-dialog__label {
    font-size: 16px;
  }
}
.md-edit-dialog__content {
  display: flex;
  padding: 24px;
  padding-bottom: 8px;
}
.md-edit-dialog__content:not(:first-child) {
  padding-top: 0;
}
.md-edit-dialog__header {
  font-weight: 500;
}
.md-edit-dialog__header.md-text-field {
  font-size: 12px;
}
.md-edit-dialog__blocked-field {
  height: 47px;
  width: 250px;
}
.md-edit-dialog__blocked-field.md-edit-dialog__blocked-field {
  padding-bottom: 0;
  padding-top: 0;
}
.md-edit-dialog__blocked-field .md-text-field-icon-container {
  align-items: center;
}

.md-table-column--select-field {
  padding-left: 24px;
}

.md-select-field-column .md-select-field--btn {
  height: 47px;
}

.md-table-footer--pagination .md-table-column {
  padding-left: 0;
}

.md-table-pagination {
  height: 56px;
}
.md-table-pagination--controls {
  align-items: center;
  display: inline-flex;
  justify-content: flex-start;
  position: absolute;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .md-table-pagination .md-text-field {
    font-size: 13px;
  }
  .md-table-pagination .md-icon-text:first-child {
    padding-right: 4px;
  }
  .md-table-pagination__label {
    display: none;
  }
}
.md-table-card-header {
  position: relative;
}
.md-table-card-header--no-title {
  align-items: center;
  display: flex;
  height: 80px;
  padding-right: 2px;
}
.md-table-card-header .md-card-title {
  padding-right: 2px;
}
.md-table-card-header .md-card-title:last-child {
  padding-bottom: 16px;
}
.md-table-card-header .md-btn--dialog + .md-btn--dialog {
  margin-left: 8px;
}

.md-card-title--contextual {
  background: #fafafa;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.md-card-title--title-contextual {
  color: #fafafa;
  font-size: 16px;
  font-weight: 500;
  line-height: 80px;
}

.md-drop-down-enter {
  transform: translate3d(0, -100%, 0);
}
.md-drop-down-enter.md-drop-down-enter-active {
  transform: translate3d(0, 0, 0);
  transition-duration: 0.15s;
  transition-property: transform;
}

.md-drop-down-leave {
  transform: translate3d(0, 0, 0);
}
.md-drop-down-leave.md-drop-down-leave-active {
  transform: translate3d(0, -100%, 0);
  transition-duration: 0.15s;
  transition-property: transform;
}

.md-data-table--fixed {
  overflow-y: hidden;
}
.md-data-table__fixed-wrapper {
  display: table;
  min-width: 100%;
  position: relative;
}
.md-data-table__fixed-wrapper--header {
  padding-top: 56px;
}
.md-data-table__fixed-wrapper--footer {
  padding-bottom: 48px;
}
.md-data-table__scroll-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
}

.md-table-column--fixed {
  height: 0;
  padding-bottom: 0;
  padding-top: 0;
  visibility: hidden;
  white-space: nowrap;
}
.md-table-column--fixed > * {
  display: none;
}
.md-table-column--fixed .md-table-column__fixed {
  display: block;
}

.md-table-column__fixed {
  position: absolute;
  visibility: visible;
}
.md-table-column__fixed--header {
  top: 0;
}
.md-table-column__fixed--footer {
  bottom: 0;
}
.md-table-column__fixed--flex {
  align-items: center;
  display: flex;
}
.md-table-column__fixed--flex-right {
  justify-content: flex-end;
}
.md-table-column__fixed .md-table-checkbox--header {
  display: flex;
  height: 56px;
}
.md-table-column__fixed .md-table-checkbox--footer {
  display: flex;
  height: 48px;
}

.md-dialog-container.md-overlay {
  transition-duration: 0.3s;
  z-index: 20;
}

.md-dialog {
  width: 280px;
  cursor: auto;
  position: fixed;
}

.md-dialog--centered {
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  max-height: calc(100% - 48px);
  max-width: calc(100% - 80px);
}
.md-dialog--centered .md-list {
  padding-bottom: 8px;
  padding-top: 0;
}
.md-dialog--centered .md-list-tile {
  height: auto;
  padding: 16px 24px;
}
.md-dialog--centered .md-tile-text--primary {
  white-space: normal;
}

.md-dialog--centered-enter {
  transform: translate3d(-50%, calc(-50% + -30px), 0);
}
.md-dialog--centered-enter.md-dialog--centered-enter-active {
  transform: translate3d(-50%, -50%, 0);
  transition-duration: 0.3s;
  transition-property: transform;
}

.md-dialog--centered-leave {
  transform: translate3d(-50%, -50%, 0);
}
.md-dialog--centered-leave.md-dialog--centered-leave-active {
  transform: translate3d(-50%, calc(-50% + -30px), 0);
  transition-duration: 0.3s;
  transition-property: transform;
}

.md-dialog--full-page {
  bottom: 0;
  left: 0;
  overflow: auto;
  top: 0;
  width: 100vw;
  z-index: 110;
}

.md-dialog--full-page-enter {
  transform: scale(0);
}
.md-dialog--full-page-enter.md-dialog--full-page-enter-active {
  transform: scale(1);
  transition-duration: 0.3s;
  transition-property: transform;
}

.md-dialog--full-page-leave {
  transform: scale(1);
}
.md-dialog--full-page-leave.md-dialog--full-page-leave-active {
  transform: scale(0);
  transition-duration: 0.3s;
  transition-property: transform;
}

.md-title--dialog {
  margin-bottom: 0;
  padding: 24px;
  padding-bottom: 20px;
  white-space: normal;
}

.md-dialog-content {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.md-dialog-content--padded {
  padding: 24px;
}
.md-dialog-content--padded:not(:first-child) {
  padding-top: 0;
}

.md-dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.md-dialog-footer--inline {
  padding: 8px;
}
.md-dialog-footer--inline .md-btn--dialog + .md-btn--dialog {
  margin-left: 8px;
}

.md-dialog-footer--stacked {
  align-items: flex-end;
  flex-direction: column;
  padding-bottom: 8px;
  padding-right: 8px;
}
.md-dialog-footer--stacked .md-btn--dialog {
  margin-bottom: 6px;
  margin-top: 6px;
}

.md-btn--dialog {
  height: 36px;
  min-width: 64px;
  padding-left: 8px;
  padding-right: 8px;
}

.md-divider {
  background: rgba(0, 0, 0, 0.12);
  border: 0;
  content: "";
  display: block;
  height: 1px;
  margin: 0;
}

.md-divider--vertical {
  height: 100%;
  width: 1px;
}

.md-divider--inset {
  margin-left: 72px;
}

.md-divider-border {
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0;
}

.md-divider-border--top {
  border-top-width: 1px;
}

.md-divider-border--right {
  border-right-width: 1px;
}

.md-divider-border--bottom {
  border-bottom-width: 1px;
}

.md-divider-border--left {
  border-left-width: 1px;
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
  .md-drawer--left {
    max-width: 320px;
    transform: translate3d(-100%, 0, 0);
    width: calc(100vw - 56px);
  }
  .md-drawer--right {
    left: 0;
    transform: translate3d(100%, 0, 0);
  }
  .md-drawer--mini.md-drawer--mini {
    width: 48px;
  }
  .md-list-tile--mini.md-list-tile--mini {
    padding-left: 12px;
    padding-right: 12px;
  }
  .md-drawer-relative--mini.md-drawer-relative--mini {
    margin-left: 48px;
  }
  .md-toolbar ~ .md-list--drawer {
    height: calc(100% - 56px);
  }
}
@media screen and (min-width: 320px) and (min-aspect-ratio: 13/9) {
  .md-toolbar ~ .md-list--drawer {
    height: calc(100% - 48px);
  }
}
@media screen and (min-width: 768px) {
  .md-drawer--left {
    max-width: 400px;
    transform: translate3d(-256px, 0, 0);
    width: 256px;
  }
  .md-drawer--right {
    transform: translate3d(100%, 0, 0);
  }
  .md-drawer--mini.md-drawer--mini {
    width: 72px;
  }
  .md-list-tile--mini.md-list-tile--mini {
    padding-left: 26px;
    padding-right: 26px;
  }
  .md-drawer-relative {
    margin-left: 256px;
  }
  .md-drawer-relative--mini.md-drawer-relative--mini {
    margin-left: 72px;
  }
  .md-toolbar ~ .md-list--drawer {
    height: calc(100% - 64px);
  }
}
.md-drawer {
  transition-duration: 0.3s;
  transition-property: transform;
}
.md-drawer--fixed {
  bottom: 0;
  position: fixed;
  top: 0;
  z-index: 17;
}
.md-drawer--inline {
  display: inline-block;
  height: 100%;
}
.md-drawer--left {
  left: 0;
}
.md-drawer--right {
  right: 0;
}
.md-drawer--active {
  transform: translate3d(0, 0, 0);
}
.md-drawer--mini {
  z-index: 16;
}

.md-list--drawer {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  overflow-y: auto;
}

.md-overlay--drawer.md-overlay--drawer {
  transition-duration: 0.3s;
}

.md-expansion-panel {
  background: #fff;
  transition-duration: 0.15s;
  transition-property: margin;
}
.md-expansion-panel--expanded:not(:first-child) {
  margin-top: 16px;
}
.md-expansion-panel--expanded:not(:last-child) {
  margin-bottom: 16px;
}

.md-panel-column:not(:last-child) {
  padding-right: 16px;
}
.md-panel-column--overflown {
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
}

.md-panel-column:not(:last-child) {
  padding-right: 16px;
}

.md-panel-header {
  align-items: center;
  display: flex;
  font-size: 15px;
  height: 48px;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  transition-duration: 0.15s;
  transition-property: background, height;
}
.md-panel-header--expanded {
  height: 64px;
}
.md-panel-header--focused {
  background: #eee;
}

.md-panel-content {
  padding: 0 24px 16px;
}

.md-panel-secondary-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.md-file-input {
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0;
}

.md-file-input-container {
  position: relative;
}
.md-file-input-container .md-btn {
  display: block;
}

.md-ink-container {
  border-radius: inherit;
  height: 100%;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.md-ink-container--2x {
  height: 200%;
  left: -50%;
  top: -50%;
  width: 200%;
}

.md-ink {
  background: rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  display: block;
  opacity: 1;
  position: absolute;
  transform: scale(0);
  z-index: -1;
}

.md-ink--active {
  transition-duration: 0.45s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.md-ink--expanded {
  transform: scale(1);
}

.md-ink--leaving {
  opacity: 0;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.md-icon {
  color: rgba(0, 0, 0, 0.54);
  font-size: 24px;
  text-align: center;
  transition-duration: 0.15s;
  transition-property: color;
  user-select: none;
}

svg.md-icon {
  fill: currentColor;
  height: 24px;
  width: 24px;
}

.md-icon-separator {
  align-items: center;
  display: flex;
  font-weight: inherit;
  text-align: left;
}
.md-icon-separator .md-icon {
  flex-grow: 0;
  flex-shrink: 0;
}

.md-icon-text {
  flex-grow: 1;
  flex-shrink: 0;
  font-weight: inherit;
  line-height: inherit;
}
.md-icon-text:first-child {
  padding-right: 16px;
}
.md-icon-text:last-child {
  padding-left: 16px;
}

@media screen and (min-width: 1025px) {
  .material-icons.md-icon {
    font-size: 20px;
  }
  svg.md-icon {
    height: 20px;
    width: 20px;
  }
}
.md-layover-enter {
  transform: scale(0);
}
.md-layover-enter.md-layover-enter-active {
  transform: scale(1);
  transition-duration: 0.2s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.md-layover-leave {
  opacity: 1;
}
.md-layover-leave.md-layover-leave-active {
  opacity: 0;
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.md-layover-child {
  position: fixed;
  z-index: 100;
}
.md-layover-child--tl {
  transform-origin: 0 0;
}
.md-layover-child--tr {
  transform-origin: 100% 0;
}
.md-layover-child--bl {
  transform-origin: 0 100%;
}
.md-layover-child--br {
  transform-origin: 100% 100%;
}
.md-layover-child--below {
  transform-origin: 50% 0;
}
.md-layover-child--below.md-layover-enter {
  transform: scaleY(0);
}
.md-layover-child--below.md-layover-enter.md-layover-enter-active {
  transform: scaleY(1);
}

.md-layover--simplified {
  position: relative;
}

.md-layover-child--simplified {
  position: absolute;
}

.md-list {
  background: #fff;
  user-select: none;
}
.md-list .md-avatar,
.md-list .md-icon {
  flex-shrink: 0;
}
.md-list .md-divider:not(.md-list-item--divider-vertical) {
  margin-bottom: 8px;
  margin-top: 8px;
}
.md-list .md-list:not(.md-list--menu) {
  background: inherit;
}

.md-list-tile {
  align-items: center;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  text-decoration: none;
  transition-duration: 0.15s;
  transition-property: background;
  height: 56px;
}
.md-list-tile--active {
  background: rgba(0, 0, 0, 0.12);
}

.md-list-item--inset {
  padding-left: 72px;
}
.md-list-item--flex {
  display: flex;
}
.md-list-item--button-grow {
  flex-grow: 1;
}
.md-list-item--divider-vertical {
  flex-shrink: 0;
  height: calc(100% - 4px);
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 2px;
  width: 2px;
}
.md-list-item--children-inline {
  align-self: center;
  padding-right: 2px;
}

.md-tile-content {
  flex-grow: 1;
  overflow: hidden;
}
.md-tile-content--left-icon {
  padding-left: 32px;
}
.md-tile-content--left-avatar {
  padding-left: 16px;
}
.md-tile-content--right-padding {
  padding-right: 16px;
}

.md-tile-addon {
  line-height: 1.4285714286;
}
.md-tile-addon--icon {
  height: 24px;
}
.md-tile-addon--avatar {
  height: 40px;
}

.md-text--theme-primary .md-icon {
  color: inherit;
}

.md-tile-text--secondary, .md-tile-text--primary {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.md-tile-text--primary {
  font-size: 16px;
}

.md-list-tile--avatar {
  height: 72px;
}

.md-tile-text--three-lines {
  white-space: pre-line;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.md-list-tile--three-lines .md-tile-addon {
  align-self: flex-start;
}

.md-list--nested-1 .md-list-tile {
  padding-left: 72px;
}

.md-list--nested-2 .md-list-tile {
  padding-left: 108px;
}

.md-list-control {
  flex-grow: 1;
}
.md-list-control .md-selection-control-label {
  width: 100%;
}
.md-list-control .md-selection-control-label > span {
  width: 100%;
}

.md-tile-content--left-button {
  padding-left: 24px;
}

.md-list-control--right {
  justify-content: flex-end;
}

.md-list-tile--control-left {
  padding-left: 0;
}

.md-list-tile--control-right {
  padding-right: 0;
}

@media screen and (min-width: 320px) {
  .md-list {
    padding-bottom: 8px;
    padding-top: 8px;
  }
  .md-tile-text--secondary {
    font-size: 14px;
  }
  .md-list-tile--two-lines {
    height: 72px;
  }
  .md-list-tile--three-lines {
    height: 88px;
  }
  .md-list-tile--three-lines .md-tile-text--secondary {
    height: 40px;
  }
  .md-list-tile--three-lines .md-tile-addon {
    margin-top: 14px;
  }
}
@media screen and (min-width: 1025px) {
  .md-list {
    padding-bottom: 4px;
    padding-top: 4px;
  }
  .md-tile-text--secondary {
    font-size: 14px;
  }
  .md-list-tile--two-lines {
    height: 60px;
  }
  .md-list-tile--three-lines {
    height: 76px;
  }
  .md-list-tile--three-lines .md-tile-text--secondary {
    height: 40px;
  }
  .md-list-tile--three-lines .md-tile-addon {
    margin-top: 12px;
  }
  .md-tile-addon--icon {
    height: 20px;
  }
  .md-tile-content--left-icon {
    padding-left: 36px;
  }
}
.md-list--inline {
  display: flex;
  padding: 0;
}

.md-media {
  display: block;
  height: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.md-media--16-9 {
  padding-bottom: 56.25%;
}

.md-media--4-3 {
  padding-bottom: 75%;
}

.md-media--1-1 {
  padding-bottom: 100%;
}

.md-media-overlay {
  background: rgba(0, 0, 0, 0.54);
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.md-media-overlay .md-btn,
.md-media-overlay .md-text {
  color: #fff;
}
.md-media-overlay .md-text--secondary {
  color: rgba(255, 255, 255, 0.7);
}

.md-list--menu {
  min-width: 112px;
}
.md-list--menu-restricted {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}
.md-list--menu-contained {
  width: 100%;
}
.md-list--menu-below {
  left: 0;
  top: 100%;
  transform-origin: 50% 0;
}
.md-list--menu-tr {
  right: 0;
  top: 0;
  transform-origin: 100% 0;
}
.md-list--menu-tl {
  top: 0;
  transform-origin: 0 0;
}
.md-list--menu-br {
  right: 0;
  transform-origin: 100% 100%;
}
.md-list--menu-bl {
  transform-origin: 0 100%;
}

@media screen and (min-width: 1025px) {
  .md-list--menu-cascading {
    padding-bottom: 16px;
    padding-top: 16px;
  }
  .md-list--menu-cascading .md-list-tile {
    padding-left: 24px;
    padding-right: 24px;
  }
  .md-list--menu-cascading .md-list-tile:not(.md-list-tile--two-lines):not(.md-list-tile--three-lines) {
    height: 32px;
  }
  .md-list--menu-cascading .md-tile-text--primary {
    font-size: 15px;
  }
  .md-list--menu-cascading .md-collapser {
    transform: rotate3d(0, 0, 1, -90deg);
  }
  .md-list--menu-cascading .md-collapser--flipped {
    transform: rotate3d(0, 0, 1, 90deg);
  }
}
@media screen and (min-width: 320px) {
  .md-list--menu-restricted {
    max-height: 272px;
  }
}
@media screen and (min-width: 1025px) {
  .md-list--menu-restricted {
    max-height: 264px;
  }
}
@media screen and (min-width: 320px) {
  .md-navigation-drawer-content {
    height: calc(100vh - 56px);
  }
}
@media screen and (min-width: 320px) and (min-aspect-ratio: 13/9) {
  .md-navigation-drawer-content {
    height: calc(100vh - 48px);
  }
}
@media screen and (min-width: 768px) {
  .md-navigation-drawer-content {
    height: calc(100vh - 64px);
  }
  .md-title.md-title--persistent-offset {
    margin-left: 216px;
  }
}
@media screen and (min-width: 1025px) {
  .md-title.md-title--persistent-offset {
    margin-left: 226px;
  }
}
.md-toolbar.md-toolbar--over-drawer {
  z-index: 19;
}

.md-title--drawer-active.md-title--drawer-active {
  transition-duration: 0.3s;
  transition-property: margin-left;
}

.md-navigation-drawer-content {
  display: block;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}
.md-navigation-drawer-content:focus {
  outline-style: none;
}
.md-navigation-drawer-content--inactive {
  margin-left: 0;
}
.md-navigation-drawer-content--active {
  transition-duration: 0.3s;
  transition-property: margin-left;
}
.md-navigation-drawer-content--prominent-offset {
  height: calc(100vh - 128px);
}

.md-title.md-title--permanent-offset {
  margin-left: 276px;
}

.md-cross-fade-enter {
  opacity: 0.01;
  transform: translate3d(0, 16px, 0);
}
.md-cross-fade-enter.md-cross-fade-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform, opacity;
}

.md-overlay {
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition-duration: 0.15s;
  transition-property: opacity;
  z-index: 16;
}
.md-overlay--active {
  opacity: 1;
}

.md-paper--0 {
  box-shadow: none;
}

@media screen and (min-width: 1025px) {
  .md-paper--0-hover {
    transition-duration: 0.3s;
    transition-property: box-shadow;
  }
  .md-paper--0-hover:hover {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4);
  }
}
.md-paper--1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.md-paper--2 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.4);
}

.md-paper--3 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4);
}

.md-paper--4 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4);
}

.md-paper--5 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4);
}

.md-picker-container {
  position: relative;
}

.md-picker-content-container {
  background: #fff;
}

.md-picker--inline {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 12;
}

.md-picker--inline-icon {
  left: 40px;
}

.md-picker-control {
  padding: 0;
}

.md-picker-text {
  color: rgba(255, 255, 255, 0.7);
}
.md-picker-text.md-picker-text > * {
  color: inherit;
}
.md-picker-text > * {
  font-weight: inherit;
  margin: 0;
  transition-duration: 0.15s;
  transition-property: color, font;
}

.md-picker-text--active {
  color: #fff;
  font-weight: 500;
}

.md-dialog--picker {
  -webkit-overflow-scrolling: touch;
  max-height: calc(100% - 16px);
  max-width: calc(100% - 16px);
  overflow: auto;
  width: auto;
}

.md-dialog-content--picker {
  padding: 0;
}

.md-picker-header {
  background: #9e9e9e;
  padding: 24px;
}

@media (orientation: portrait) {
  .md-picker {
    width: 330px;
  }
  .md-picker .md-picker-header {
    height: 110px;
  }
  .md-picker .md-time-periods {
    display: inline-block;
    padding-left: 1em;
    padding-right: 24px;
  }
  .md-picker .md-picker-content {
    height: 340px;
  }
  .md-picker .md-display-3 {
    font-size: 5em;
    line-height: 1;
  }
  .md-picker .md-calendar-dows {
    padding-bottom: 12px;
  }
  .md-picker.md-picker--date .md-display-1 {
    display: inline-block;
  }
  .md-picker .md-calendar-date--btn {
    height: 44px;
  }
  .md-picker .md-calendar-date--btn::after {
    height: 36px;
    width: 36px;
  }
  .md-picker .md-clock-face {
    height: 282px;
    width: 282px;
  }
  .md-picker .md-clock-hand {
    width: 121px;
  }
  .md-picker .md-clock-hand--inner {
    width: 85px;
  }
}
@media (orientation: landscape) {
  .md-picker {
    display: flex;
    width: 560px;
  }
  .md-picker .md-picker-header {
    width: 190px;
  }
  .md-picker .md-picker-content {
    height: 280px;
    width: 370px;
  }
  .md-picker .md-calendar-date--btn {
    height: 35px;
  }
  .md-picker .md-calendar-date--btn::after {
    height: 32px;
    width: 32px;
  }
  .md-picker.md-picker--time .md-picker-header {
    padding-top: 93.3333333333px;
  }
  .md-picker .md-clock-face {
    height: 244px;
    width: 244px;
  }
  .md-picker .md-clock-hand {
    width: 102px;
  }
  .md-picker .md-clock-hand--inner {
    width: 66px;
  }
  .md-picker .md-time-periods {
    margin-left: auto;
    margin-right: 2.5em;
    width: 35px;
  }
  .md-picker .md-display-3 {
    font-size: 3.25em;
  }
}
@media (max-width: 320px) and (orientation: portrait) {
  .md-picker {
    width: 304px;
  }
}
@media (max-height: 320px) and (orientation: landscape) {
  .md-picker .md-picker-content {
    height: 256px;
    width: calc(100vw - 206px);
  }
}
.md-picker--date .md-picker-control {
  display: block;
}

.md-picker-content--calendar {
  padding-left: 12px;
  padding-right: 12px;
}

.md-calendar-controls {
  align-items: center;
  display: flex;
}
.md-calendar-controls .md-title {
  flex-grow: 1;
  font-size: 16px;
  margin-bottom: 0;
  text-align: center;
}

.md-calendar-date {
  margin: 0;
  width: 14.2857142857%;
}

.md-picker-content--year {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.md-years {
  list-style: none;
  margin: 0;
  padding: 0;
}

.md-year {
  font-size: 16px;
  padding: 12px;
  transition-duration: 0.15s;
  transition-property: color, font-size;
}
.md-year:focus:not(.md-year--active) {
  font-size: 20px;
}
.md-year--active {
  font-size: 24px;
  font-weight: 500;
}

.md-calendar-date--btn::after {
  background: #9e9e9e;
  border-radius: 50%;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) scale(0);
  transition-duration: 0.15s;
  transition-property: transform;
  z-index: 0;
}

.md-calendar-date--btn-active {
  font-weight: 700;
}
.md-calendar-date--btn-active::after {
  transform: translateX(-50%) translateY(-50%) scale(1);
}

.md-calendar-date--date {
  position: relative;
  z-index: 1;
}

.md-calendar-dow {
  font-size: 13px;
  font-weight: 700;
  line-height: 1.4285714286;
}

.md-picker-content--clock {
  padding: 24px;
  padding-bottom: 12px;
}

.md-time-periods .md-picker-control {
  display: block;
}

.md-time-period {
  font-size: 1.1428571429em;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.md-clock-face {
  background: #f5f5f5;
  border-radius: 50%;
  position: relative;
}

.md-clock-hand {
  height: 2px;
  position: absolute;
  transform-origin: 0 0;
  transition-duration: 0.15s;
  transition-property: width;
  z-index: 1;
}
.md-clock-hand--active {
  transition-property: transform, width;
}
.md-clock-hand::before, .md-clock-hand::after {
  background: #9e9e9e;
  border-radius: 50%;
  content: "";
  position: absolute;
}
.md-clock-hand::before {
  height: 6px;
  left: -3px;
  top: -2px;
  width: 6px;
}
.md-clock-hand::after {
  height: 36px;
  right: -16px;
  top: -18px;
  transition-duration: 0.15s;
  transition-property: background, border, transform;
  width: 36px;
}
.md-clock-hand--minute-hover::after {
  transform: scale(0.4);
}

.md-clock-time {
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: center;
  position: absolute;
  width: 36px;
  z-index: 8;
}
.md-clock-time:focus {
  outline-style: none;
}

.md-progress {
  display: block;
  margin-bottom: 1em;
  margin-top: 1em;
}

.md-progress--circular-determinate {
  transition-duration: 0.15s;
  transition-property: transform;
}

.md-progress--circular-indeterminate {
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-name: md-circular-progress;
  animation-timing-function: linear;
}

.md-circular-progress-path {
  fill: none;
  stroke: #fafafa;
  stroke-dasharray: 187px;
}

.md-circular-progress-path--animated {
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-name: md-circular-progress-dash;
  animation-timing-function: ease-ine-out;
  stroke-dashoffset: 0;
}

@keyframes md-circular-progress {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(135deg);
  }
  75% {
    transform: rotate(450deg);
  }
  100% {
    transform: rotate(720deg);
  }
}
@keyframes md-circular-progress-dash {
  0% {
    stroke-dashoffset: 187px;
  }
  50% {
    stroke-dashoffset: 46.75px;
  }
  100% {
    stroke-dashoffset: 187px;
  }
}
.md-progress--linear {
  height: 3px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.md-progress--linear-active {
  background: #fafafa;
}

.md-progress--linear-determinate {
  height: 100%;
  position: absolute;
  z-index: 1;
}

.md-progress--linear-indeterminate::before, .md-progress--linear-indeterminate::after {
  background: inherit;
  bottom: 0;
  content: "";
  position: absolute;
  top: 0;
  will-change: left, right;
  z-index: 1;
}
.md-progress--linear-indeterminate::before {
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-name: md-linear-indeterminate;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.md-progress--linear-indeterminate::after {
  animation-delay: 1.15s;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-name: md-linear-indeterminate-short;
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.md-progress--linear-query::before, .md-progress--linear-query::after {
  animation-direction: reverse;
}

@keyframes md-linear-indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes md-linear-indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
.md-select-field {
  cursor: inherit;
  display: flex;
  flex-wrap: wrap;
  user-select: none;
}
.md-select-field .md-divider {
  width: 100%;
}
.md-select-field__toggle {
  position: relative;
}

.md-select-field--btn.md-select-field--btn {
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 16px;
  padding-top: 16px;
}

.md-select-field--text-field {
  pointer-events: none;
}

.md-drop-enter {
  transform: translate3d(0, -6px, 0);
}
.md-drop-enter.md-drop-enter-active {
  transform: translate3d(0, 0, 0);
  transition-duration: 0.3s;
  transition-property: transform;
}

@media screen and (min-width: 320px) {
  .md-select-field--btn {
    height: 48px;
  }
  .md-select-field--text-field {
    height: 18px;
  }
}
@media screen and (min-width: 1025px) {
  .md-select-field--btn {
    height: 40px;
  }
  .md-select-field--text-field {
    height: 15px;
  }
}
.md-selection-control-container--inline {
  display: inline-block;
}

.md-selection-control-input {
  height: 0;
  position: absolute;
  visibility: hidden;
  width: 0;
}

.md-selection-control-label {
  align-items: center;
  display: inline-flex;
}

.md-selection-control-group {
  border: 0;
}

.md-switch-container {
  align-items: center;
  display: flex;
  height: 48px;
}
.md-switch-container.md-selection-control-container--inline {
  display: inline-flex;
}

.md-switch-track {
  border-radius: 8px;
  flex-shrink: 0;
  height: 16px;
  margin-left: 12px;
  margin-right: 12px;
  position: relative;
  width: 40px;
}

.md-switch-track--on {
  background: rgba(158, 158, 158, 0.5);
}

.md-switch-track--off {
  background: rgba(0, 0, 0, 0.38);
}

.md-switch-track--disabled {
  background: rgba(0, 0, 0, 0.12);
}

.md-switch-thumb {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  content: "";
  display: block;
  height: 24px;
  position: absolute;
  transition-duration: 0.15s;
  transition-property: background, transform;
  width: 24px;
}

.md-switch-thumb--on {
  background: #9e9e9e;
  transform: translate3d(20px, -4px, 0);
}

.md-switch-thumb--off {
  background: #fafafa;
  transform: translate3d(-4px, -4px, 0);
}

.md-switch-thumb--disabled {
  background: #bdbdbd;
}

@media screen and (min-width: 320px) {
  .md-selection-control-label {
    font-size: 16px;
  }
}
@media screen and (min-width: 1025px) {
  .md-selection-control-label {
    font-size: 16px;
  }
  .md-selection-control-container {
    height: 40px;
  }
}
.md-slider-container {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
}

.md-slider-label {
  display: block;
  width: 100%;
}

.md-slider-input {
  height: 0;
  position: absolute;
  visibility: hidden;
  width: 0;
}

.md-slider-track {
  background: rgba(0, 0, 0, 0.26);
  content: "";
  display: block;
  flex-grow: 1;
  flex-shrink: 0;
  height: 2px;
  margin-bottom: 23px;
  margin-top: 23px;
  position: relative;
}

.md-slider-track-fill {
  background: #9e9e9e;
  border: 0;
  height: 2px;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  transition-duration: 0.15s;
  transition-property: width;
}

.md-slider-track-fill--dragging {
  transition-property: none;
}

.md-slider-thumb {
  border-radius: 50%;
  content: "";
  display: inline-block;
  height: 14px;
  position: absolute;
  top: -6px;
  transition-duration: 0.15s;
  transition-property: left, transform, background;
  width: 14px;
  z-index: 5;
}
.md-slider-thumb:focus {
  outline-style: none;
}

.md-slider-thumb--on {
  background: #9e9e9e;
}

.md-slider-thumb--active {
  transform: scale(1.5);
  transform-origin: center;
}

.md-slider-thumb--dragging {
  transition-property: transform, background;
}

.md-slider-thumb--disabled {
  background: rgba(0, 0, 0, 0.26);
  transform: scale(0.75);
}

.md-slider-thumb--continuous-off {
  background: #fff;
  border: 2px solid;
  border-color: rgba(0, 0, 0, 0.26);
  position: relative;
}

.md-slider-thumb--mask {
  transform: scale(1);
}

.md-slider-thumb--mask-inked {
  background: rgba(158, 158, 158, 0.15);
  transform: scale(3.5);
  z-index: 4;
}

.md-slider-thumb--mask-disabled {
  background: #fff;
  border-radius: 0;
  transform: scale(1.1);
  z-index: 4;
}

.md-slider-thumb--discrete::after {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid transparent;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  top: 10px;
  transition-duration: 0.15s;
  transition-property: border-top-color, transform;
  width: 0;
}
.md-slider-thumb--discrete-active {
  top: 0;
  transform: scale(2) translate3d(0, -18px, 0);
}
.md-slider-thumb--discrete-on::after {
  border-top-color: #9e9e9e;
}
.md-slider-thumb--discrete-off {
  background: #000;
}
.md-slider-thumb--discrete-active-off {
  background: #bdbdbd;
}
.md-slider-thumb--discrete-active-off::after {
  border-top-color: #bdbdbd;
}
.md-slider-thumb--discrete-mask-inked {
  transform: scale(2.5);
  transition-duration: 0.3s;
}
.md-slider-thumb--discrete-mask-leaving {
  background: rgba(158, 158, 158, 0.3);
  transition-duration: 0.3s;
  z-index: 4;
}

.md-slider-discrete-value {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 12px;
  height: 28px;
  justify-content: center;
  position: absolute;
  text-align: center;
  transform: translate3d(-7px, -43px, 0);
  transition-duration: 0.15s;
  transition-property: left;
  width: 28px;
  z-index: 6;
}

.md-slider-discrete-tick {
  background: #000;
  content: "";
  height: 2px;
  position: absolute;
  top: 0px;
  z-index: 4;
}

.md-slider-discrete-value--dragging {
  transition-property: none;
}

.md-slider-editor {
  text-align: right;
}

.md-slider-container .md-text-field-container {
  margin-left: 4px;
  padding-right: 0;
}

@media screen and (min-width: 320px) {
  .md-slider-ind {
    font-size: 16px;
    margin-top: 14px;
  }
}
@media screen and (min-width: 1025px) {
  .md-slider-ind {
    font-size: 16px;
    margin-top: 16px;
  }
}
.md-slider-container .md-icon {
  margin-bottom: 12px;
  margin-top: 12px;
}

.md-slider-ind {
  display: block;
  text-align: center;
}

.md-slider-track--ind-left {
  margin-left: 16px;
}

.md-slider-track--ind-right {
  margin-right: 16px;
}

.md-subheader {
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  line-height: 48px;
  padding-left: 16px;
  padding-right: 16px;
}

.md-snackbar-container {
  position: absolute;
}

.md-snackbar {
  align-items: center;
  background: #323232;
  bottom: 0;
  display: flex;
  height: 48px;
  justify-content: space-between;
  position: fixed;
  transform-origin: 0 100%;
  z-index: 20;
}

.md-snackbar--multiline {
  height: 80px;
}

.md-snackbar--toast {
  color: #fff;
  margin-bottom: 0;
  padding-left: 24px;
  padding-right: 24px;
}

.md-snackbar-enter-active,
.md-snackbar-leave-active {
  transition-duration: 0.3s;
  transition-property: transform;
}
.md-snackbar-enter-active *,
.md-snackbar-leave-active * {
  transition-duration: 0.3s;
  transition-property: opacity;
}

.md-snackbar-enter.md-snackbar-enter-active {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.md-snackbar-leave.md-snackbar-leave-active {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.md-snackbar-enter *,
.md-snackbar-leave.md-snackbar-leave-active * {
  opacity: 0;
}

.md-snackbar-enter.md-snackbar-enter-active *,
.md-snackbar-leave * {
  opacity: 1;
}

.md-btn--snackbar {
  padding-left: 24px;
  padding-right: 24px;
}

@media screen and (min-width: 320px) {
  .md-snackbar {
    left: 0;
    right: 0;
  }
  .md-snackbar-enter,
  .md-snackbar-leave.md-snackbar-leave-active {
    transform: translate3d(0, 100%, 0);
  }
  .md-snackbar-enter.md-snackbar-enter-active,
  .md-snackbar-leave {
    transform: translate3d(0, 0, 0);
  }
  .md-snackbar--action {
    padding-right: 0;
  }
  .md-btn--snackbar-floating {
    transition: bottom 0.3s, background 0.15s, box-shadow 0.15s, color 0.15s;
    will-change: bottom;
  }
  .md-btn--snackbar-floating-adjust {
    bottom: 64px;
  }
  .md-btn--snackbar-floating-multiline-adjust {
    bottom: 96px;
  }
}
@media screen and (min-width: 768px) {
  .md-snackbar {
    border-radius: 2px;
    left: 50%;
    max-width: 568px;
    min-width: 288px;
    right: auto;
    transform: translate3d(-50%, 0, 0);
  }
  .md-snackbar-enter,
  .md-snackbar-leave.md-snackbar-leave-active {
    transform: translate3d(-50%, 100%, 0);
  }
  .md-snackbar-enter.md-snackbar-enter-active,
  .md-snackbar-leave {
    transform: translate3d(-50%, 0, 0);
  }
  .md-snackbar--action {
    padding-right: 24px;
  }
  .md-btn--snackbar-floating-adjust {
    bottom: 24px;
  }
  .md-btn--snackbar-floating-multiline-adjust {
    bottom: 24px;
  }
}
.md-tabs {
  display: flex;
  position: relative;
}
.md-tabs.md-background--primary .md-ink {
  background: rgba(255, 255, 255, 0.12);
}
.md-tabs--centered {
  justify-content: center;
}
.md-tabs--centered .md-tab {
  flex-grow: 0;
}
.md-tabs--pagination .md-tab {
  flex-grow: 0;
}

.md-tab {
  align-items: center;
  color: inherit;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  height: 48px;
  justify-content: flex-end;
  overflow: hidden;
  padding-bottom: 20px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}
.md-tab--multiline {
  font-size: 12px;
  padding-bottom: 12px;
}
.md-tab--icon {
  height: 72px;
  padding-bottom: 16px;
}
.md-tab--inactive {
  color: rgba(255, 255, 255, 0.7);
}

.md-tab-indicator {
  background: #fafafa;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
}

.md-btn--tab-overflow {
  bottom: 2px;
  position: absolute;
}
.md-btn--tab-overflow--icon {
  bottom: 8px;
}
.md-btn--tab-overflow-left {
  left: 6px;
  z-index: 3;
}
.md-btn--tab-overflow-right {
  right: 12px;
}

.md-menu--tab {
  margin-right: 100%;
}
.md-menu--tab .md-icon {
  color: inherit;
}
.md-menu--tab .md-tab {
  padding-top: 12px;
}

.md-icon--tab {
  color: inherit;
  margin-bottom: 10px;
}

.md-tab-toolbar {
  width: 100%;
}

.md-tabs-fixed-container {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 15;
}

.md-tabs-content--offset {
  margin-top: 48px;
}
.md-tabs-content--offset-icon {
  margin-top: 72px;
}

.md-tabs-content--offset-toolbar-prominent {
  margin-top: 176px;
}
.md-tabs-content--offset-toolbar-prominent-icon {
  margin-top: 200px;
}

@media screen and (min-width: 320px) {
  .md-toolbar ~ .md-tabs {
    margin-top: -1px;
  }
  .md-tabs {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
  .md-tab {
    max-width: calc(100vw - 56px);
    min-width: 72px;
  }
  .md-tab-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
  }
}
@media screen and (min-width: 320px) and (max-aspect-ratio: 13/9) {
  .md-tabs-content--offset-toolbar {
    margin-top: 104px;
  }
  .md-tabs-content--offset-toolbar-icon {
    margin-top: 128px;
  }
}
@media screen and (min-width: 320px) and (min-aspect-ratio: 13/9) {
  .md-tabs-content--offset-toolbar {
    margin-top: 96px;
  }
  .md-tabs-content--offset-toolbar-icon {
    margin-top: 120px;
  }
}
@media screen and (min-width: 768px) {
  .md-tabs-content--offset-toolbar {
    margin-top: 112px;
  }
  .md-tabs-content--offset-toolbar-icon {
    margin-top: 136px;
  }
}
@media screen and (min-width: 1025px) {
  .md-toolbar ~ .md-tabs {
    margin-top: 0;
  }
  .md-tabs {
    overflow: visible;
  }
  .md-tab {
    max-width: 264px;
    min-width: 160px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .md-tab-label {
    font-size: 13px;
    line-height: 13px;
  }
  .md-icon--tab {
    margin-bottom: 12px;
    margin-top: 2px;
  }
}
.md-text-field-container {
  position: relative;
}

.md-text-field-container--input {
  line-height: 1.15;
}

.md-text-field-container--input-block {
  display: flex;
}
.md-text-field-container--input-block .md-text-field-message-container {
  flex-shrink: 0;
}

.md-text-field-multiline-container,
.md-text-field-container--multiline {
  transition-duration: 0.15s;
  transition-property: height;
}

.md-text-field-multiline-container {
  position: relative;
}

.md-text-field-container--multiline {
  display: flex;
  flex-direction: column;
}

.md-text-field {
  background: none;
  border: 0;
  line-height: inherit;
  padding: 0;
  width: 100%;
}
.md-text-field:placeholder {
  color: rgba(0, 0, 0, 0.54);
}
.md-text-field:-moz-placeholder {
  color: rgba(0, 0, 0, 0.54);
}
.md-text-field::-moz-placeholder {
  color: rgba(0, 0, 0, 0.54);
}
.md-text-field:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.54);
}
.md-text-field::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.54);
}
.md-text-field:focus {
  outline-style: none;
}
.md-text-field[type=search] {
  -webkit-appearance: textfield;
}
.md-text-field:-webkit-autofill, .md-text-field:-webkit-autofill:focus {
  box-shadow: 0 0 0 50px #fff inset;
}
.md-text-field--margin {
  margin-top: 13px;
}
.md-text-field[disabled]:placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.md-text-field[disabled]:-moz-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.md-text-field[disabled]::-moz-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.md-text-field[disabled]:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.md-text-field[disabled]::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
}

.md-text-field--multiline {
  padding-bottom: 0;
  padding-top: 0;
  resize: none;
}

.md-text-field--multiline-mask {
  overflow: hidden;
  position: absolute;
  visibility: hidden;
}

.md-divider--text-field {
  margin-top: 7px;
  overflow: visible;
}
.md-divider--text-field::after {
  background: #9e9e9e;
  content: "";
  display: block;
  height: 2px;
  transition-duration: 0.15s;
  transition-property: width;
  width: 0;
}

.md-divider--text-field-expanded::after {
  width: 100%;
}

.md-divider--text-field-error,
.md-divider--text-field-error::after {
  background: #f44336;
}

.md-divider--expand-from-left::after {
  left: 0;
}

.md-divider--expand-from-center::after {
  margin: auto;
}

.md-divider--expand-from-right::after {
  right: 0;
}

.md-floating-label {
  cursor: text;
  line-height: 1;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition-duration: 0.15s;
  transition-property: transform, font-size, color;
  white-space: nowrap;
}

.md-floating-label--floating {
  font-size: 16px;
}

.md-floating-label--icon-offset {
  left: 40px;
}
@media screen and (min-width: 1025px) {
  .md-floating-label--icon-offset {
    left: 36px;
  }
}

.md-text-field-message-container {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
}

.md-text-field-message-container--count-only {
  justify-content: flex-end;
}

.md-text-field-message-container--left-icon-offset {
  padding-left: 40px;
}
.md-text-field-message-container--right-icon-offset {
  padding-right: 40px;
}

.md-text-field-message {
  color: inherit;
  flex-grow: 1;
  line-height: 1.4285714286;
  transition-duration: 0.15s;
  transition-property: opacity;
  white-space: normal;
}

.md-text-field-message--counter {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 16px;
}

.md-text-field-message--inactive {
  opacity: 0;
}

.md-text-field-message--active {
  opacity: 1;
}

.md-text-field-divider-container {
  display: inline-block;
}

.md-text-field-divider-container--grow {
  display: block;
  flex-grow: 1;
}

.md-text-field-icon + .md-text-field-divider-container,
.md-text-field-divider-container + .md-text-field-icon {
  margin-left: 16px;
}

.md-text-field-icon-container {
  align-items: flex-end;
  display: flex;
  width: 100%;
}
.md-text-field-container--input-block .md-text-field-icon-container {
  align-items: center;
}

.md-text-field-inline-indicator {
  position: absolute;
  right: 0;
}

.md-text-field--inline-indicator {
  width: calc(100% - 24px);
}

.md-password-btn {
  background: transparent;
  border: 0;
  height: 24px;
  padding: 0;
  width: 24px;
}
.md-password-btn:not(.md-password-btn--focus):focus {
  outline-style: none;
}
.md-password-btn .md-icon {
  color: inherit;
}

.md-password-btn--active::before {
  background: rgba(0, 0, 0, 0.54);
}

.md-password-btn--invisible::before, .md-password-btn--invisible::after {
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  top: 50%;
  width: 100%;
}
.md-password-btn--invisible::before {
  transform: translate3d(-1px, -50%, 0) rotate3d(0, 0, 1, 45deg);
}
.md-password-btn--invisible::after {
  background: #fafafa;
  transform: translate3d(1px, -50%, 0) rotate3d(0, 0, 1, 45deg);
}

@media screen and (min-width: 320px) {
  .md-text-field-container--input-block {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .md-text-field-container--multiline-block {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .md-text-field-container--padded-block {
    padding-left: 20px;
    padding-right: 20px;
  }
  .md-text-field {
    font-size: 16px;
  }
  .md-text-field--floating-margin {
    margin-top: 37px;
  }
  .md-divider--text-field {
    margin-bottom: 8px;
  }
  .md-floating-label--inactive {
    transform: translate3d(0, 43px, 0);
  }
  .md-floating-label--inactive-sized {
    font-size: 16px;
  }
  .md-floating-label--floating {
    transform: translate3d(0, 16px, 0);
  }
  .md-text-field-icon--positioned {
    margin-bottom: 13.5px;
  }
  .md-text-field-inline-indicator {
    top: 9px;
  }
  .md-text-field-inline-indicator--floating {
    top: 34px;
  }
  .md-text-field-inline-indicator--block {
    top: 17px;
  }
  .md-floating-label--inactive-title {
    transform: translate3d(0, 42px, 0);
  }
}
@media screen and (min-width: 1025px) {
  .md-text-field-container--input-block {
    padding-bottom: 16px;
    padding-top: 16px;
  }
  .md-text-field-container--multiline-block {
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .md-text-field-container--padded-block {
    padding-left: 16px;
    padding-right: 16px;
  }
  .md-text-field {
    font-size: 16px;
  }
  .md-text-field--floating-margin {
    margin-top: 33px;
  }
  .md-divider--text-field {
    margin-bottom: 4px;
  }
  .md-floating-label--inactive {
    transform: translate3d(0, 39px, 0);
  }
  .md-floating-label--inactive-sized {
    font-size: 16px;
  }
  .md-floating-label--floating {
    transform: translate3d(0, 12px, 0);
  }
  .md-text-field-icon--positioned {
    margin-bottom: 9.5px;
  }
  .md-password-btn.md-password-btn {
    height: 20px;
    width: 20px;
  }
  .md-text-field-message-container--left-icon-offset {
    padding-left: 36px;
  }
  .md-text-field-message-container--right-icon-offset {
    padding-right: 36px;
  }
  .md-text-field-inline-indicator {
    top: 9px;
  }
  .md-text-field-inline-indicator--floating {
    top: 30px;
  }
  .md-text-field-inline-indicator--block {
    top: 12px;
  }
  .md-floating-label--inactive-title {
    transform: translate3d(0, 36px, 0);
  }
}
.md-text-field--title {
  font-size: 34px;
}

.md-floating-label--title {
  transition-duration: 0.25s;
}

.md-floating-label--inactive-title {
  font-size: 34px;
}

@media screen and (min-width: 320px) {
  .md-toolbar-relative {
    margin-top: 56px;
  }
  .md-toolbar-relative--padding {
    padding-top: 56px;
  }
  .md-toolbar {
    height: 56px;
  }
  .md-btn--toolbar {
    margin-bottom: 4px;
    margin-top: 4px;
  }
  .md-toolbar .md-btn--text {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .md-toolbar--action-left {
    margin-left: 4px;
  }
  .md-toolbar--action-right {
    margin-right: 4px;
  }
  .md-title--toolbar {
    line-height: 56px;
    margin-left: 20px;
  }
  .md-title--toolbar-offset {
    margin-left: 72px;
  }
  .md-select-field--toolbar.md-select-field--toolbar {
    margin-bottom: 6px;
    margin-top: 6px;
  }
}
@media screen and (min-width: 320px) and (min-aspect-ratio: 13/9) {
  .md-toolbar-relative {
    margin-top: 48px;
  }
  .md-toolbar-relative--padding {
    padding-top: 48px;
  }
  .md-toolbar {
    height: 48px;
  }
  .md-btn--toolbar {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .md-toolbar .md-btn--text {
    margin-bottom: 6px;
    margin-top: 6px;
  }
  .md-toolbar--action-left {
    margin-left: 4px;
  }
  .md-toolbar--action-right {
    margin-right: 4px;
  }
  .md-title--toolbar {
    line-height: 48px;
    margin-left: 20px;
  }
  .md-title--toolbar-offset {
    margin-left: 72px;
  }
}
@media screen and (min-width: 768px) {
  .md-toolbar-relative {
    margin-top: 64px;
  }
  .md-toolbar-relative--padding {
    padding-top: 64px;
  }
  .md-toolbar {
    height: 64px;
  }
  .md-btn--toolbar {
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .md-toolbar .md-btn--text {
    margin-bottom: 14px;
    margin-top: 14px;
  }
  .md-toolbar--action-left {
    margin-left: 12px;
  }
  .md-toolbar--action-right {
    margin-right: 12px;
  }
  .md-title--toolbar {
    line-height: 64px;
    margin-left: 20px;
  }
  .md-title--toolbar-offset {
    margin-left: 80px;
  }
  .md-select-field--toolbar.md-select-field--toolbar {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
@media screen and (min-width: 768px) and (min-aspect-ratio: 13/9) {
  .md-select-field--toolbar.md-select-field--toolbar {
    margin-bottom: 12px;
    margin-top: 12px;
  }
}
@media screen and (min-width: 1025px) {
  .md-toolbar--action-left {
    margin-left: 14px;
  }
  .md-toolbar--action-right {
    margin-right: 14px;
  }
  .md-title--toolbar {
    margin-left: 26px;
  }
  .md-title--toolbar-offset {
    margin-left: 80px;
  }
  .md-btn--toolbar {
    margin-bottom: 12px;
    margin-top: 12px;
  }
  .md-toolbar .md-btn--text {
    margin-bottom: 16px;
    margin-top: 16px;
  }
}
.md-toolbar {
  display: flex;
}
.md-toolbar--fixed {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 15;
}
.md-toolbar--inset {
  margin: 8px;
  max-width: calc(100vw - 16px);
}
.md-toolbar--text-white {
  color: #fff;
}
.md-toolbar--text-white .md-btn, .md-toolbar--text-white .md-icon, .md-toolbar--text-white .md-title--toolbar, .md-toolbar--text-white .md-text-field, .md-toolbar--text-white .md-select-field {
  color: inherit;
}
.md-toolbar--text-white :placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.md-toolbar--text-white :-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.md-toolbar--text-white ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.md-toolbar--text-white :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.md-toolbar--text-white ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.md-toolbar--themed {
  background: #f5f5f5;
}
.md-toolbar--themed .md-title--toolbar {
  color: rgba(0, 0, 0, 0.87);
}
.md-toolbar--prominent {
  height: 128px;
}

.md-title--toolbar {
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 0;
  transition-duration: 0.15s;
  transition-property: transform;
}
.md-title--toolbar-prominent {
  position: absolute;
  transform: translate3d(0, 64px, 0);
}

.md-toolbar-relative--prominent {
  margin-top: 128px;
}
.md-toolbar-relative--prominent-padding {
  padding-top: 128px;
}

.md-toolbar .md-text-field-container {
  padding-bottom: 0;
  padding-top: 0;
}
.md-toolbar .md-text-field--toolbar {
  font-size: 20px;
}

.md-toolbar .md-autocomplete-container,
.md-toolbar .md-autocomplete {
  height: 100%;
}

@media screen and (min-width: 320px) {
  .md-tooltip {
    font-size: 14px;
    padding: 9px 16px;
  }
  .md-tooltip--top {
    top: -24px;
  }
  .md-tooltip--right {
    right: -24px;
  }
  .md-tooltip--bottom {
    bottom: -24px;
  }
  .md-tooltip--left {
    left: -24px;
  }
}
@media screen and (min-width: 1025px) {
  .md-tooltip {
    font-size: 10px;
    padding: 6px 8px;
  }
  .md-tooltip--top {
    top: -14px;
  }
  .md-tooltip--right {
    right: -14px;
  }
  .md-tooltip--bottom {
    bottom: -14px;
  }
  .md-tooltip--left {
    left: -14px;
  }
}
.md-tooltip-container {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  user-select: none;
  width: 100%;
  z-index: 1;
}

.md-tooltip {
  background: #616161;
  color: #fff;
  display: block;
  font-weight: 500;
  opacity: 0.9;
  outline-style: none;
  position: absolute;
  white-space: nowrap;
}
.md-tooltip--active {
  transition-duration: 0.15s;
  transition-property: opacity, transform;
}
.md-tooltip--enter, .md-tooltip--leave-active {
  opacity: 0;
}
.md-tooltip--enter-active {
  opacity: 0.9;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.md-tooltip--leave-active {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.md-tooltip--horizontal {
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}
.md-tooltip--vertical {
  top: 50%;
  transform: translate3d(0, -50%, 0);
}
.md-tooltip--top-active {
  transform: translate3d(-50%, -100%, 0);
}
.md-tooltip--right-active {
  transform: translate3d(100%, -50%, 0);
}
.md-tooltip--bottom-active {
  transform: translate3d(-50%, 100%, 0);
}
.md-tooltip--left-active {
  transform: translate3d(-100%, -50%, 0);
}

.md-text {
  color: rgba(0, 0, 0, 0.87);
}
.md-text--secondary {
  color: rgba(0, 0, 0, 0.54);
}
.md-text--disabled {
  color: rgba(0, 0, 0, 0.38);
}
.md-text--theme-primary {
  color: #9e9e9e;
}
.md-text--theme-secondary {
  color: #fafafa;
}
.md-text--error {
  color: #f44336;
}
.md-text--inherit.md-text--inherit {
  color: inherit;
}

.md-ink--primary .md-ink {
  background: rgba(158, 158, 158, 0.26);
}

.md-ink--secondary .md-ink {
  background: rgba(250, 250, 250, 0.26);
}

.md-background {
  background: #fafafa;
}
.md-background--card {
  background: #fff;
}
.md-background--primary {
  background: #9e9e9e;
  color: #fff;
}
.md-background--secondary {
  background: #fafafa;
  color: #fff;
}

@media screen and (min-width: 1025px) {
  .md-background--primary-hover:hover {
    background: rgba(158, 158, 158, 0.9);
  }
  .md-background--secondary-hover:hover {
    background: rgba(250, 250, 250, 0.9);
  }
}
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto";
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
body::-webkit-scrollbar {
  display: none;
}

p {
  color: #9e9e9e;
}

@media only screen and (max-width: 1024px) {
  .md-navigation-drawer-content {
    -webkit-overflow-scrolling: auto;
  }
}
@media only screen and (max-width: 1024px) {
  .noscroll .md-navigation-drawer-content {
    -webkit-overflow-scrolling: auto;
    height: 0px !important;
  }
}

.Toaster__alert {
  background-color: #323232;
  overflow: hidden;
  max-width: 650px;
  position: relative;
  border-radius: 0.4rem;
  display: flex;
  padding: 1rem;
  padding-right: 48px;
  box-shadow: rgba(52, 58, 64, 0.15) 0px 1px 10px 0px, rgba(52, 58, 64, 0.1) 0px 6px 12px 0px, rgba(52, 58, 64, 0.12) 0px 6px 15px -2px;
}

.Toaster__alert_text {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: white;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  line-height: 1.5;
  font-size: 1rem;
  margin: 0px;
}

.Toaster__alert_close {
  padding: 12px;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  color: white;
  position: absolute;
  top: 7px;
  right: 4px;
  border-radius: 0.4rem;
  border: 0;
  -webkit-appearance: none;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.Toaster__alert_close:focus {
  box-shadow: rgba(52, 58, 64, 0.15) 0px 0px 0px 3px;
}

.Toaster__message-wrapper {
  padding: 8px;
}

